<template>
	<div class="address-user" >
		
			<van-address-list
			  v-model="chosenAddressId"
			  :list="addressList"			 			 
			  default-tag-text="默认"
			  @add="onAdd"
			  @edit="onEdit"
			  :item-bottom="item"
			  :switchable="false"
			/>
			
		<van-empty class="custom-image" image="https://img.yzcdn.cn/vant/custom-empty-image.png" description="请添加地址" v-if="addressStatus"/>
	</div>
</template>

<script>
	import { Toast } from 'vant';
	import {getUserAddressList} from '../../api/user.js'
	export default {
	  data() {
	    return {
	      chosenAddressId: '',
		  addressList:[],
	     addressStatus:false
	    
	    };
	  },
	  mounted() {
	  	this.getUserAddressList()
	  },
	  methods: {
		  getUserAddressList(){
			getUserAddressList().then((res)=>{
			
				if(res.data){
					this.addressList = res.data
					res.data.forEach((v,i)=>{
						
						if(v.isDefault){
							this.chosenAddressId = v.id
						}
					})
				}else{
					this.addressStatus = true
				}
				
			})  
		  },
	    onAdd() {
	      this.$router.push({
			  path:'/addressEdit'
		  })
	    },
	    //编辑地址
	    onEdit(item, index) {
	          
	    	  this.$router.push({
	    		  path:'/addressEdit',
	    		  query:{
	    			  id:item.id
	    		  }
	    	  })
	        },
	  },
	};
</script>

<style scoped lang="less">
#app{
	div{
		// height: 100%;
		background-color: #f6f6f6;
		.address-user{
			height: calc(100% -50px);
			
			.van-address-list{
				.van-radio-group{
					.van-address-item{
					// background-color: #666666;	
					}
				}
			}
			
		}
	}
	
}
	
</style>
